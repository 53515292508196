var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0 overflow-hidden"},[_c('div',{staticClass:"mt-5"},[_c('v-card',{staticClass:"mx-4",attrs:{"elevation":"0"}},[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","lg":"6","xl":"6"}},[_c('v-text-field',{staticClass:"mt-4 mr-2",attrs:{"dense":"","rules":_vm.org_name_rules,"outlined":"","label":"Organization Name *"},model:{value:(_vm.org_name),callback:function ($$v) {_vm.org_name=$$v},expression:"org_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","lg":"6","xl":"6"}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"disabled":"","filled":"","dense":"","rules":_vm.email_rules,"outlined":"","label":"Organization E-mail *"},model:{value:(_vm.org_email),callback:function ($$v) {_vm.org_email=$$v},expression:"org_email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","rules":_vm.address_rules,"dense":"","outlined":"","label":"Organization Address *"},model:{value:(_vm.org_address),callback:function ($$v) {_vm.org_address=$$v},expression:"org_address"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"12","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"label":"Country Code *","disabled":"","filled":"","items":_vm.CountryList,"item-value":"phonecode","item-text":"name","dense":"","rules":_vm.country_code_rules,"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.flag)+" ("+_vm._s(item.phonecode.toString().includes("+") == false ? "+" + item.phonecode : item.phonecode)+") ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.flag)+" "+_vm._s(item.name)+" ("+_vm._s(item.phonecode)+") ")]}}]),model:{value:(_vm.country_code),callback:function ($$v) {_vm.country_code=$$v},expression:"country_code"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"4","xl":"4","md":"4"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"disabled":"","filled":"","dense":"","outlined":"","label":"Contact Number *","rules":_vm.contact_number_rules},model:{value:(_vm.contact_number),callback:function ($$v) {_vm.contact_number=$$v},expression:"contact_number"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5","lg":"5","xl":"5"}},[_c('v-text-field',{attrs:{"dense":"","rules":_vm.gst_rules,"outlined":"","label":"Organization GST"},model:{value:(_vm.org_gst),callback:function ($$v) {_vm.org_gst=$$v},expression:"org_gst"}})],1)],1)],1),_c('div',{staticClass:"ml-2 font-weight-medium"},[_vm._v("Organization Logo")]),_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[(_vm.url == null)?_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.url == null)?_c('v-card',{staticClass:"ml-2 rounded-lg",staticStyle:{"border":"1px dashed grey"},attrs:{"width":"300px","height":"200px","outlined":"","elevation":hover ? 16 : 2}},[_c('v-row',{staticStyle:{"margin-top":"80px"},attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"grey--text",attrs:{"accept":"image/*","text":"","depressed":""},on:{"click":function($event){return _vm.handleUploadImage()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"primary","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,true)},[_vm._v("UPLOAD LOGO "),_c('v-icon',{attrs:{"dark":"","right":"","color":"grey"}},[_vm._v(" mdi-cloud-upload ")]),(_vm.url == null)?_c('input',{ref:"excel-upload-input",staticClass:"excel-upload-input",attrs:{"src":_vm.url,"append-icon":"mdi-paperclip","type":"file","accept":"image/*"},on:{"change":_vm.handleClick}}):_vm._e()],1)],1)],1):_vm._e()]}}],null,false,2206512909)}):_vm._e(),(_vm.url != null)?_c('v-card',{staticClass:"ml-2",attrs:{"width":"300px","height":"200px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.url != null)?_c('v-img',{staticClass:"ml-n2 mt-16",attrs:{"contain":"","max-width":"300px","src":_vm.url,"height":"50px","label":"Add Image","color":"#D97D54"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}):_vm._e()],1),_c('v-spacer'),_c('v-card-actions',[_c('v-row',{attrs:{"align":"end","justify":"end"}},[_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-16 mr-3 white--text gradient-bg",attrs:{"depressed":"","x-small":""},on:{"click":function($event){return _vm.clearImage()}}},on),[_vm._v("DELETE "),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"white","x-small":""}},[_vm._v("mdi-delete ")])],1)]}}],null,false,3584879327)},[_c('span',{staticClass:"white--text"},[_vm._v("Delete Logo")])])],1)],1)],1):_vm._e()],1)],1),_c('v-toolbar',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"gradient-bg white--text ml-5 pa-3",attrs:{"small":""},on:{"click":function($event){return _vm.validateform()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-content-save")]),_vm._v("save Details ")],1)],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }