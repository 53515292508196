<template>
  <v-card class="elevation-0 overflow-hidden">
    <div class="mt-5">
      <v-card class="mx-4" elevation="0">
        <v-form ref="form">
          <v-row class="mx-2" no-gutters>
            <v-col cols="12" md="6" sm="6" lg="6" xl="6">
              <v-text-field
                v-model="org_name"
                class="mt-4 mr-2"
                dense
                :rules="org_name_rules"
                outlined
                label="Organization Name *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" lg="6" xl="6">
              <v-text-field
                v-model="org_email"
                disabled
                filled
                class="mt-4"
                dense
                :rules="email_rules"
                outlined
                label="Organization E-mail *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" lg="12" xl="12">
              <v-textarea
                v-model="org_address"
                rows="1"
                auto-grow
                :rules="address_rules"
                dense
                outlined
                label="Organization Address *"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="3" sm="12" lg="3" xl="3">
              <v-autocomplete
                label="Country Code *"
                v-model="country_code"
                disabled
                filled
                :items="CountryList"
                item-value="phonecode"
                item-text="name"
                dense
                :rules="country_code_rules"
                outlined
                class="mr-2"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.flag }} ({{
                    item.phonecode.toString().includes("+") == false
                      ? "+" + item.phonecode
                      : item.phonecode
                  }})
                </template>
                <template slot="item" slot-scope="{ item }">
                  {{ item.flag }} {{ item.name }} ({{ item.phonecode }})
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" lg="4" xl="4" md="4">
              <v-text-field
                disabled
                filled
                v-model="contact_number"
                dense
                outlined
                class="mr-2"
                label="Contact Number *"
                :rules="contact_number_rules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" sm="5" lg="5" xl="5">
              <v-text-field
                v-model="org_gst"
                dense
                :rules="gst_rules"
                outlined
                label="Organization GST"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="ml-2 font-weight-medium">Organization Logo</div>
        <v-row no-gutters class="mt-5">
          <v-col cols="12" md="12" sm="12" lg="12" xl="12">
            <v-hover v-slot="{ hover }" open-delay="200" v-if="url == null">
              <v-card
                width="300px"
                height="200px"
                class="ml-2 rounded-lg"
                outlined
                style="border: 1px dashed grey"
                :elevation="hover ? 16 : 2"
                v-if="url == null"
              >
                <v-row align="center" justify="center" style="margin-top: 80px">
                  <v-btn
                    class="grey--text"
                    accept="image/*"
                    text
                    depressed
                    @click="handleUploadImage()"
                    >UPLOAD LOGO
                    <v-icon dark right color="grey"> mdi-cloud-upload </v-icon>
                    <input
                      :src="url"
                      v-if="url == null"
                      ref="excel-upload-input"
                      class="excel-upload-input"
                      append-icon="mdi-paperclip"
                      type="file"
                      accept="image/*"
                      @change="handleClick"
                    />
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" color="primary" dark label small>
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-btn>
                </v-row>
              </v-card>
            </v-hover>
            <v-card
              width="300px"
              height="200px"
              class="ml-2"
              v-if="url != null"
            >
              <v-row align="center" justify="center">
                <v-img
                  contain
                  max-width="300px"
                  class="ml-n2 mt-16"
                  :src="url"
                  height="50px"
                  v-if="url != null"
                  v-model="files"
                  label="Add Image"
                  color="#D97D54"
                >
                </v-img>
              </v-row>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-row align="end" justify="end">
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        class="mt-16 mr-3 white--text gradient-bg"
                        @click="clearImage()"
                        x-small
                        v-on="on"
                        >DELETE

                        <v-icon color="white" class="ml-1" x-small
                          >mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span class="white--text">Delete Logo</span>
                  </v-tooltip>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-toolbar flat class="mt-2"
          ><v-spacer />
          <v-btn
            @click="validateform()"
            small
            class="gradient-bg white--text ml-5 pa-3"
          >
            <v-icon class="mr-2">mdi-content-save</v-icon>save Details
          </v-btn></v-toolbar
        >
        <!-- <v-row class="mt-n3">
        <v-spacer></v-spacer>
        <v-col  v-if="$vuetify.breakpoint.name != 'xs'" cols="12" sm="3"  md="3" lg="3" xl="3">
          <v-btn
            @click="validateform()"
            small
            class="gradient-bg white--text ml-5 "
          >
            <v-icon class="mr-2">mdi-content-save</v-icon>save Details
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-spacer></v-spacer>
        <v-col  v-if="$vuetify.breakpoint.name == 'xs'" cols="12" sm="2" md="2" lg="2" xl="2">
          <v-btn
            @click="validateform()"
            small
            class="gradient-bg white--text ml-5"
          >
            <v-icon class="mr-2">mdi-content-save</v-icon>save Details
          </v-btn>
        </v-col>
      </v-row> -->
        <!-- <v-row no-gutters>
        <v-spacer />
        <v-col cols="12" md="7" sm="12" lg="7" xl="7">
          <v-carousel hide-delimiters cycle height="300px" :show-arrows="true">
            <v-carousel-item contain src="@/assets/refimg3.jpg">
            </v-carousel-item>
            <v-carousel-item contain src="@/assets/refimg4.jpg">
            </v-carousel-item>
            <v-carousel-item contain src="@/assets/refimg2.jpg">
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" md="5" sm="12" lg="5" xl="5">
          <v-row no-gutters>
            <v-col cols="12" md="12" sm="12" lg="12" xl="12">
              <v-toolbar dense elevation="0">
                <v-card-text
                  style="margin-top: 300px; font-weight: bold"
                  align=""
                  ><h2>{{ ref_code }}</h2>
                </v-card-text>
                <v-card-text style="margin-top: 300px; font-weight: bold">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="black--text"
                        icon
                        @click="callcopymethod()"
                        v-clipboard:copy="`${whatsapptext}`"
                        text
                        x-large
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span class="white--text">Copy to clipboard</span>
                  </v-tooltip>
                </v-card-text>
                <v-card-text style="margin-top: 300px; font-weight: bold">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="green--text"
                        icon
                        @click="callwhatsappmethod()"
                        text
                        x-large
                      >
                        <v-icon>mdi-whatsapp</v-icon>
                      </v-btn>
                    </template>
                    <span class="white--text">Share via Whatsapp</span>
                  </v-tooltip>
                </v-card-text>
                <v-card-text style="margin-top: 300px; font-weight: bold">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="red--text"
                        icon
                        @click="callmailmethod()"
                        text
                        x-large
                      >
                        <v-icon>mdi-email</v-icon>
                      </v-btn>
                    </template>
                    <span class="white--text">Share via Mail</span>
                  </v-tooltip>
                </v-card-text>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
      </v-card>
      <Overlay :overlay="overlay" />
    </div>
  </v-card>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { EditOrganisationdetails } from "@/graphql/mutations.js";
import { Country } from "country-state-city";
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
import Overlay from "@/components/Overlay.vue";
var AWS = require("aws-sdk");
export default {
  mixins: [GetCurrentUserDetailsnew],
  components: {
    Overlay,
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  data: () => ({
    overlay: false,
    org_name: "",
    org_email: "",
    org_address: "",
    country_code_rules: [(x) => x.length != 0 || "Select Country Code"],
    country_code: "",
    contact_number: "",
    OrgDetails: {},
    url: null,
    files: [],
    whatsapptext: "",
    ref_code: "",
    org_gst: "",
    org_name_rules: [
      (v) => v.length != 0 || "Organization Name cannot be empty",
      (v) =>
        v.length >= 3 ||
        "Organization Name must contain more than 3 characters",
      (v) =>
        v.length <= 50 ||
        "Organization Name cannot exceed more than 50 characters",
    ],
    address_rules: [
      (v) => v.length != 0 || "Organization Address cannot be empty",
      (v) =>
        v.length >= 3 ||
        "Organization Address must contain more than 3 characters",
      (v) =>
        v.length <= 300 ||
        "Organization Address cannot exceed more than 300 characters",
    ],
    gst_rules: [(v) => !v || v.length == 15 || "Enter valid GST Number"],
    contact_number_rules: [
      (x) => x.length != "" || "Contact Number cannot be empty",
      (v) => /^[0-9]{8,12}$/.test(v) || "Contact Number must be valid",
    ],
    email_rules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    CountryList: [],
  }),
  created() {
    this.overlay = true;
    this.url = this.$store.getters.get_org_details[0].organisation_logo;
    // var data = this.$store.getters.get_access_details;
    // let uint8array = new Uint8Array(JSON.parse(data));
    // // ENCODER

    // var array = new TextDecoder("utf-8").decode(uint8array);
    // var asseceskey = JSON.parse(array);
    // console.log(asseceskey);
    // console.log(this.$store.getters.get_access_details);
  },
  mounted() {
    this.GetCurrentUserDetailsnew();
  },
  watch: {
    // files(val) {
    //   if (val == null) {
    //     this.url = null;
    //   }
    // },

    GetCurrentOrgObject(val) {
      this.org_name = val[0].organisation_name;
      this.org_address = val[0].organisation_address;
      this.org_email = val[0].user_email_id;
      this.contact_number = val[0].organisation_contact_number;
      this.org_gst = val[0].organisation_gst_number;
      this.ref_code = val[0].referral_code;
      this.CountryList = Country.getAllCountries();
      fetch(
        "https://api.geoapify.com/v1/ipinfo?apiKey=7dc7ae8d1cc54ab2ae3753110b2a7726"
      ).then((resp) => resp.json());
      let a = this.CountryList.filter((valll) =>
        valll.phonecode.includes("+")
          ? valll.phonecode == val[0].organisation_country_code
          : "+" + valll.phonecode == val[0].organisation_country_code
      );
      this.country_code = a[0];
      setTimeout(() => (this.overlay = false), 410);
    },
  },
  methods: {
    callcopymethod() {
      this.whatsapptext = `We use an extremely simple and easy to use solution -  'Prezence' to manage attendance of employees at our business. You may want to try it for your business as well. Download and install the Prezence mobile app to get started - https://www.prznce.com/Apps/prezence/ with ${this.ref_code} as the referral code`;
      this.$store.commit("snackbar/showMessage", {
        content: "Copied to clipboard Successfully!",
        color: "green",
      });
    },
    callwhatsappmethod() {
      this.whatsapptext = `We use an extremely simple and easy to use solution -  'Prezence' to manage attendance of employees at our business. You may want to try it for your business as well. Download and install the Prezence mobile app to get started - https://www.prznce.com/Apps/prezence/ with ${this.ref_code} as the referral code`;
      window.open(`https://wa.me/?text=${this.whatsapptext}`);
    },
    callmailmethod() {
      this.whatsapptext = `We use an extremely simple and easy to use solution -  'Prezence' to manage attendance of employees at our business. You may want to try it for your business as well. Download and install the Prezence mobile app to get started - https://www.prznce.com/Apps/prezence/ with ${this.ref_code} as the referral code`;
      window.open(`mailto:?subject=ReferAndEarn&body=${this.whatsapptext}`);
    },
    async clearImage() {
      this.overlay = true;
      var self = this;
      var keyValue = this.url.replace(
        "https://przncemembersprofile.s3.ap-south-1.amazonaws.com/",
        ""
      );

      var params = {
        Bucket: "przncemembersprofile",
        Key: keyValue,
      };
      var data = this.$store.getters.get_access_details;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      var asseceskey = JSON.parse(array);
      var s3Client = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: asseceskey.access_key,
        secretAccessKey: asseceskey.secret_key,
      });

      this.overlay = false;
      await s3Client.deleteObject(params, function (err) {
        if (err) {
          return null;
        } else {
          self.url = null;
        }
      });
    },
    uploadMediaToS3() {
      this.overlay = true;
      var self = this;

      if (self.files.length != 0) {
        self.url = URL.createObjectURL(self.files);
        const fr = new FileReader();
        fr.readAsDataURL(self.files);
        fr.addEventListener("load", async () => {
          var imageUrl = [];
          imageUrl = fr.result.replace(
            "data:" + self.files.type + ";base64,",
            ""
          );
          var keyValue = `${
            this.$store.getters.get_org_details[0].organisation_id
          }/organisationlogo/${Math.floor(
            new Date().getTime() / 1000.0
          )}/${self.files.name.replace(" ", "")}`;

          var params = {
            Bucket: "przncemembersprofile",
            Key: keyValue,
            Body: Buffer.from(imageUrl, "base64"),
            ContentType: self.files.type,
            ACL: "public-read-write",
          };

          var data = this.$store.getters.get_access_details;
          let uint8array = new Uint8Array(JSON.parse(data));
          // ENCODER

          var array = new TextDecoder("utf-8").decode(uint8array);
          var asseceskey = JSON.parse(array);
          var s3Client = new AWS.S3({
            region: "ap-south-1",
            accessKeyId: asseceskey.access_key,
            secretAccessKey: asseceskey.secret_key,
          });
          this.overlay = false;
          await s3Client.putObject(params, function (err) {
            if (err) {
              return null;
            } else {
              self.url = `https://przncemembersprofile.s3.ap-south-1.amazonaws.com/${params.Key}`;
            }
          });
        });
      }
    },
    validateform() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        this.editOrgDetails();
      } else {
        this.$store.commit("snackbar/showMessage", {
          content: "Please enter all fields",
          color: "red",
        });
      }
    },
    handleClick(e) {
      this.files = e.target.files[0];
      if (this.files.length != 0 && this.files != null) {
        this.url = URL.createObjectURL(this.files);
        this.uploadMediaToS3();
      } else {
        return null;
      }
    },
    handleUploadImage() {
      this.$refs["excel-upload-input"].click();
    },

    async editOrgDetails() {
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          organisation_address: this.org_address,
          organisation_contact_number: this.contact_number,
          organisation_country_code:
            this.country_code.phonecode.toString().includes("+") == false
              ? "+" + this.country_code.phonecode
              : this.country_code.phonecode,
          organisation_gst_number: this.org_gst,
          organisation_name: this.org_name,
          organisation_logo: this.url == null ? "REMOVE" : this.url,
        };

        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(EditOrganisationdetails, {
            input: inputParams,
          })
        ).then((res) => {
          this.url = res.data.EditOrganisationdetails.organisation_logo;

          this.$store.commit("snackbar/showMessage", {
            content: res.data.EditOrganisationdetails.Message,
            color: "green",
          });
          setTimeout(() => this.GetCurrentUserDetailsnew(), 500);

          // this.GetCurrentUserDetails();
        });
      } catch (error) {
        console.log("er", error);
        this.$store.commit("snackbar/showMessage", {
          content: error.errors[0].message,
          color: "red",
        });
      }
    },
  },
};
</script>

<style>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>
